import "./bootstrap";
import 'regenerator-runtime/runtime';

window.Vue = require("vue").default;

import Vue from "vue";

import moment from 'moment';

// import store from "@stores";
import { createPinia, PiniaVuePlugin } from 'pinia'

// import router from '@router'
import VueRouter from "vue-router";
import router from '@router';

import vuetify from "@plugins/vuetify";

import VueHtml2Canvas from "vue-html2canvas";

Vue.use(VueHtml2Canvas);

import App from "./App.vue";

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

Vue.use(VueRouter);

Vue.APP_DOMAIN = get_app_domain();

function get_app_domain() {


    var regex_var = new RegExp(/(\.[^\.]{0,2})(\.[^\.]{0,2})(\.*$)|(\.[^\.]*)(\.*$)/);
    var host = location.hostname;
    var subdomain = host.replace(regex_var, '').split('.').shift();
    var domain = host.replace(regex_var, '').split('.').pop();
    console.log(subdomain + ' - ' + domain);
    //document.write("Returned user entered domain: " + unit_test + " - " + unit_test_2 + "<br>\n");

    //var result = location.hostname.replace(regex_var, '').split('.').pop();
    //document.write("Current Domain: " + result);

    if ( subdomain == 'dev' ) {
        subdomain = 'app';
    }

    var obj = {
        theme: subdomain,
        name: subdomain,
        domain: domain,
        location: host,
    };
    return obj;
}

Vue.filter('formatDate', function (value = null) {
    if (value === null)
        value = new Date()

    if (typeof(value) !== 'string')
        value = String(value)

    // return moment(value).format('DD/MM/YYYY hh:mm:ss')
    return moment(value).format('YYYY-MM-DD hh:mm:ss')
})


Vue.filter('dateEu', function (value = null) {
    if (value === null) {
        value = new Date()
    }

    if (typeof(value) !== 'string') {
        value = String(value)
    }

    return moment(value).format('DD/MM/YYYY HH:mm')
})


Vue.filter('printf',function() {

    return [...arguments].reduce((p,c) => p.replace(/%s/,c), this);
    // var formatted = this;
    // for( var arg in arguments ) {
    //     formatted = formatted.replace("{" + arg + "}", arguments[arg]);
    // }
    // return formatted;
})

const app = new Vue({
    render: (h) => h(App),
    pinia,
    router,
    vuetify: vuetify,
}).$mount("#app");

// router.beforeEach((to, from, next) => {
//   const authUser = store.getters["auth/authUser"];
//   const profile = store.getters["auth/profile"];
//   const roles = store.getters["auth/roles"];
//   const isAdmin = store.getters["auth/isAdmin"];
//   const reqAuth = to.matched.some((record) => record.meta.requiresAuth);

//   const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
//   const profilePath = { path: "/profile" };

//   // se non necessita autenticazione la risolvo
//   if (!reqAuth) {
//     next();
//   } else {
//     // se necessita autenticazione....
//     if (!authUser) {
//       // verifico autenticazione
//       store.dispatch("auth/getAuthUser").then(() => {
//         // se non sono autenticato vado a login
//         if (!store.getters["auth/authUser"]) {
//           next(loginQuery);
//         } else {
//           // recupero ruolo
//           store.dispatch("auth/getRoles").then(() => {
//             store.dispatch("auth/getProfile").then(() => {
//               // se non ho profilo vado a profilo
//               if (!store.getters["auth/profile"]) {
//                 next(profilePath);
//               } else {
//                 if (to.path == "/") {
//                   if ( store.getters["auth/isAdmin"] ) {
//                     next("/admin/dashboard");
//                   } else {
//                     next("/questionnaire");
//                   }
//                 } else {
//                   next();
//                 }
//               }
//             });
//           });
//         }
//       });
//     } else {
//       // se sono autenticato e non ho profilo...
//       if (!profile) {
//         if (to.path == "/profile") {
//           next();
//         } else {
//           next(profilePath);
//         }
//       } else {
//         if (to.path == "/") {
//           if ( isAdmin ) {
//             next("/admin/dashboard");
//           } else {
//             next("/questionnaire");
//           }
//         } else {
//           next();
//         }
//       }
//     }
//   }
// });