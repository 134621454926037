export default [
    {
        path: "/events",
        name: "events",
        meta: { requiresAuth: true },
        component: () => import('@views/events/Main.vue'),
        children: [
            {
                path: "",
                name: "events.index",
                meta: { requiresAuth: true, breadcrumb: { name: 'Event List', is_root: true } },
                component: () => import('@views/events/Index.vue'), 
            },
            {
                path: "/events/:id",
                name: "events.show",
                props: route => ({ id: route.params.id, readonly: true, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Event' } },
                component: () => import('@views/events/Edit.vue'), 
            },
            {
                path: "/events/:id?/edit",
                name: "events.edit",
                props: route => ({ id: route.params.id, readonly: false, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Edit Event' } },
                component: () => import('@views/events/Edit.vue'),
            },
            {
                path: "/events/:id/import-participants",
                name: "events.import-participants",
                props: true,
                meta: { requiresAuth: true, breadcrumb: { name: 'Import Event Participants' } },
                component: () => import('@views/events/participants/ImportParticipants.vue'),
            },
            {
                path: "/events/:event_id/participant-types",
                name: "participant_type.index",
                props: route => ({ event_id: route.params.event_id, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Participant type List', is_root: true } },
                component: () => import('@views/events/participant_types/Index.vue'), 
            },
            {
                path: "/events/:event_id/participant-types/add",
                name: "participant_type.add",
                props: route => ({ event_id: route.params.event_id,id: null, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Add participant type', is_root: true } },
                component: () => import('@views/events/participant_types/Edit.vue'), 
            },
            {
                path: "/events/:event_id/participant-types/:id/edit",
                name: "participant_type.edit",
                props: route => ({ event_id: route.params.event_id,id: route.params.id, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Edit participant type', is_root: true } },
                component: () => import('@views/events/participant_types/Edit.vue'), 
            },
            {
                path: "/events/:event_id/campaign/add",
                name: "event.campaign.add",
                props: route => ({ event_id: route.params.event_id,id: null, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Add new campaign', is_root: true } },
                component: () => import('@views/events/campaign/Edit.vue'), 
            },
            {
                path: "/events/:event_id/campaign/:id/edit",
                name: "event.campaign.edit",
                props: route => ({ event_id: route.params.event_id,id: route.params.id, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Edit campaign', is_root: true } },
                component: () => import('@views/events/campaign/Edit.vue'), 
            },
            {
                path: "/events/:event_id/campaigns/",
                name: "event.campaign.index",
                props: route => ({ event_id: route.params.event_id,id: null, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Campaigns', is_root: true } },
                component: () => import('@views/events/campaign/Index.vue'), 
            },
            {
                path: "/events/:event_id/sending_templates/",
                name: "event.sending_template.index",
                props: route => ({ event_id: route.params.event_id,id: null, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Sending templates', is_root: true } },
                component: () => import('@views/events/sending_templates/Index.vue'), 
            },
            {
                path: "/events/:event_id/venues/",
                name: "event.venues.index",
                props: route => ({ event_id: route.params.event_id,id: null, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Venues', is_root: true } },
                component: () => import('@views/events/venues/Index.vue'), 
            },
            {
                path: "/events/:event_id/venues/:id",
                name: "event.venues.show",
                props: route => ({ event_id: route.params.event_id,id: route.params.id, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Venues', is_root: true } },
                component: () => import('@views/events/venues/Show.vue'), 
            },
            // {
            //     path: "/events/:id/add-provider",
            //     name: "events.add-provider",
            //     props: true,
            //     meta: { requiresAuth: true },
            //     component: () => import('@views/events/AddEventProvider.vue'),
            // },
        ]
    },
]